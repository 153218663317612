// Source: https://www.goodreads.com/quotes/

let quotes = [
  "You've gotta dance like there's nobody watching,\nLove like you'll never be hurt,\nSing like there's nobody listening,\nAnd live like it's heaven on earth.",

  "You only live once, but if you do it right, once is enough.",

  "Be the change that you wish to see in the world.",

  "To live is the rarest thing in the world. Most people exist, that is all.",

  // "Here's to the crazy ones. The misfits. The rebels. The troublemakers.\nThe round pegs in the square holes. The ones who see things differently.\nThey're not fond of rules. And they have no respect for the status quo.\nYou can quote them, disagree with them, glorify or vilify them. About the only thing you can't do is ignore them.\nBecause they change things. They push the human race forward. And while some may see them as the crazy ones, we see genius.\nBecause the people who are crazy enough to think they can change the world, are the ones who do."

  "The people who are crazy enough to think they can change the world, are the ones who do.",

  "A day without sunshine is like, you know, night.",

  "All you need is love. But a little chocolate now and then doesn't hurt.",

  // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam obcaecati similique fugiat sint est natus illo, sed numquam culpa eveniet. Blanditiis dolor odio sit voluptas incidunt rerum neque doloremque fuga?"
]

export default quotes
