import React from "react";

function SocialIcons(props) {
  return (
    <div id="social-icons">
      <a
        className="fab fa-instagram"
        href="https://instagram.com/rohantinna"
      ></a>
      <a className="fab fa-twitter" href="https://twitter.com/rohantinna"></a>
      <a className="fab fa-medium-m" href="https://medium.com/@rohantinna"></a>
      <a
        className="fab fa-linkedin-in"
        href="https://linkedin.com/in/rohantinna"
      ></a>
      <a className="fab fa-github" href="https://github.com/rohantinna"></a>
      <a
        className="fas fa-music"
        href="https://itunes.apple.com/profile/rohantinna"
      ></a>
    </div>
  );
}

export default SocialIcons;
